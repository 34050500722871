<template>
  <div>
    <h2>Dateibrowser</h2>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "Media",
  data() {
    return {
      media: [],
      search: "",
      headers: [{ text: "Dateiname", value: "item.filePath" }]
    };
  },
  methods: {
    showAlert(a) {
      alert("Alert! \n" + a.name);
    },
    async loadMedia() {
      await axios
        .get(`${process.env.VUE_APP_API_URL}/media_objects.json`)
        .then(response => {
          this.media = response.data.data;
        });
    }
  },
  mounted() {
    this.loadMedia();
  }
};
</script>

<style lang="scss">
tbody {
  tr {
    &:hover {
      cursor: pointer;
    }
  }
}
</style>
